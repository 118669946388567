import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Candidate } from './candidate.model';

export interface CandidateState extends EntityState<Candidate> {}

@StoreConfig({ name: 'candidate' })
export class CandidateStore extends EntityStore<CandidateState, Candidate> {

  constructor() {
    super();
  }

}

export const candidateStore = new CandidateStore();

