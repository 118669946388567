import * as React from 'react';
import { Paper, IconButton, InputBase, withStyles, WithStyles } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';

import { styles } from './styles';

const SearchInputComponent: React.FC<React.InputHTMLAttributes<HTMLInputElement> & WithStyles<typeof styles>> = ({ classes, ...props }): JSX.Element => (
  <Paper className={classes.root} elevation={1}>
    <InputBase className={classes.input} autoFocus {...props} />
    <IconButton className={classes.iconButton} aria-label="Search">
      <SearchIcon />
    </IconButton>
  </Paper>
);

export const SearchInput = withStyles(styles)(SearchInputComponent);
