import * as React from 'react';
import { withStyles } from '@material-ui/core';
import { styles } from '@/containers/site-detail/components/ProgressDetails/styles';

const InitialProps = {
  minValue: 0,
  maxValue: 100,
  currentValue: 20,
  percentValue: '20%',
}

type ProgressContentProps = { classes?: { [k in keyof typeof styles]: string } } & typeof InitialProps;

const ProgressContentComponent: React.FC<ProgressContentProps> = (props): JSX.Element => (
  <div className={props.classes!.progress}>
    <div
      role="progressbar"
      araia-valuemin={props.minValue || 0}
      araia-valuemax={props.maxValue || 0}
      araia-valuenow={props.currentValue || 0}
      className={props.classes!.progressCompleted}
      style={{
        width: props.percentValue,
      }}
    />

  </div>
);

export default withStyles(styles)(ProgressContentComponent);
