import { createStyles } from '@material-ui/core';

export const styles = createStyles({
  root: {
    cursor: 'pointer',
    width: '100%',
  },
  avatar: {
    textTransform: 'uppercase',
  },
  content: {
    marginLeft: 16,
    flex: 1,
    minWidth: 0,
  },
});
