import * as React from 'react';
import AppWrapper from '@/components/AppWrapper';
import { Route } from 'react-router-dom';

export function PrivateRoute ({ component: Component, backButton = true, searchButton = false, title, ...rest }): JSX.Element {
  return (
    <AppWrapper title={title} backButton={backButton} searchButton={searchButton}>
      <Route
        {...rest}
        render={(props) => <Component {...props} />}
      />
    </AppWrapper>
  );
};
