import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Grid, Paper, Typography, LinearProgress, Button } from '@material-ui/core';
import { RouteProps } from 'react-router-dom';

import ItemJob from '@/components/ItemJob';
import List from '@/components/List';
import { jobService, jobQuery, Job, globalQuery } from '@/state';
import { Subscription } from 'rxjs';
import Loading from '@/components/Loading';
import SearchInput from '@/components/SearchInput';

type State = {
  jobs: Job[];
  page: number;
  loading: boolean;
  searchBy: string;
  searchActive: boolean;
};

function debounce(func, wait, immediate?) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

class SitesContainer extends React.Component<RouteProps, State> {
  state = {
    jobs: [],
    page: 0,
    searchActive: false,
    searchBy: undefined,
    loading: true,
  };

  subs: Subscription[] = [];

  async componentDidMount() {
    this.subs.push(jobQuery.selectAll().subscribe(jobs => this.setState({ jobs })));
    this.subs.push(
      jobQuery.searchBy$.subscribe(searchBy => this.setState({ searchBy }, () => jobService.fetchJobs(0)))
    );
    this.subs.push(globalQuery.searchActive$.subscribe(searchActive => this.setState({ searchActive })));
    await jobService.fetchJobs(this.state.page);
    this.setState(prevState => ({ ...prevState, loading: false }));
  }

  componentWillUnmount() {
    this.subs.forEach(s => s.unsubscribe());
  }

  loadMore = () =>
    this.setState(
      {
        page: ++this.state.page,
      },
      () => jobService.fetchJobs(this.state.page)
    );

  search = (e: React.ChangeEvent<HTMLInputElement>) => {

    const value = e.target.value;
    jobService.search(value || '');
  }

  renderSitesContent = () =>
    this.state.jobs!.length > 0 ? (
      <React.Fragment>
        <List>
          {this.state.jobs.map((job: Job, index) => (
            <ItemJob i={index} key={index} job={job} />
          ))}
        </List>
        <Grid item xs={12} style={{ width: '100%', height: 'auto' }}>
          <Button fullWidth variant="contained" color="primary" onClick={() => this.loadMore()}>
            Carregar mais
          </Button>
        </Grid>
      </React.Fragment>
    ) : null;
  // : this.renderEmptyList();

  renderEmptyList = () => (
    <div>
      <Typography>Não tem nenhum Job</Typography>
    </div>
  );

  renderLoadingOrList = () => (this.state.loading ? <Loading /> : this.renderSitesContent());

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Unidades</title>
        </Helmet>
        {this.state.searchActive ? (
          <Grid item xs={12} style={{ width: '100%', height: 'auto' }}>
            <SearchInput
              type="text"
              placeholder="Search Jobs"
              defaultValue={this.state.searchBy}
              onChange={this.search}
            />
          </Grid>
        ) : null}
        {this.renderLoadingOrList()}
      </React.Fragment>
    );
  }
}

export const Sites = SitesContainer;
