import Joi from '@hapi/joi';
import 'joi-extract-type';
import { jobSchema } from '@bigland/shared/schemas/job/job';
import { ID } from '@datorama/akita';

type ProgressType = {
  min: number;
  max: number;
  current: number;
}

export type Job = Joi.extractType<typeof jobSchema> & {
  id: ID;
  title: string;
  status: 'Planejada' | 'Concluída';
  progress?: ProgressType;
  company_name: string;
  company_picture_key: string;
  candidatesMetrics?: { [key: string]: number };
}
