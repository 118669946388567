import { createStyles } from '@material-ui/core';

export const styles = createStyles({
  root: {
    position: 'fixed',
    top: 64,
    right: 0,
    bottom: 56,
    left: 0
  },
});
