export const styles: any = theme => ({
  root: {
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    height: 20,
    padding: `0 ${theme.spacing.unit}px`,
    borderRadius: 8,
    color: '#fff',
    marginRight: 20,
  },

  active: {
    backgroundColor: '#03A9F4',
  },

  completed: {
    backgroundColor: '#2FA84F',
  },

  planned: {
    backgroundColor: '#F3AA18',
  },

  text: {
    color: 'inherit',
  }
});
