export const styles: any = theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    alignSelf: 'center',
    justifySelf: 'center',
    margin: '0 auto',
    width: 400,

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '100vh',
      justifyContent: 'center',
    },
  },
});
