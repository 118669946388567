import { Store, StoreConfig } from '@datorama/akita';
import { Organization } from '@bigland/shared/schemas/organization';
type User = any; // @TODO user schema

export interface AuthState {
  user?: User;
  processing: boolean;
  org?: Organization;
}

function createInitialState(): AuthState {
  return {
    processing: true,
  };
}

@StoreConfig({ name: 'auth' })
export class AuthStore extends Store<AuthState> {
  constructor() {
    super(createInitialState());
  }
}

export const authStore = new AuthStore();
