import { Store, StoreConfig } from '@datorama/akita';

export interface GlobalState {
  searchActive: boolean;
}

function createInitialState(): GlobalState {
  return {
    searchActive: false,
  };
}

@StoreConfig({ name: 'global' })
export class GlobalStore extends Store<GlobalState> {

  constructor() {
    super(createInitialState());
  }

}

export const globalStore = new GlobalStore();

