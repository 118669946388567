import * as React from 'react';
import posed from 'react-pose';

const ItemContent = posed.div({
  enter: {
    opacity: 1,
    y: 0,
    transition: ({ i }) => ({ delay: i * 50 })
  },
  exit: { opacity: 0, y: 30, transition: { type: 'spring' } },
});

export const Item = props => {
  return (
    <ItemContent {...props} style={{ width: '100%', height: 'auto' }}>
      {props.children}
    </ItemContent>
  );
};
