import { GlobalStore, globalStore, GlobalState } from './global.store';
import { track } from '@/utils/actions';

export class GlobalService {
  constructor(private globalStore: GlobalStore) {}

  toggleSearchField(searchActive?: boolean) {
    this.globalStore.update(state => ({
      ...state,
      searchActive: typeof searchActive !== 'undefined' ? searchActive : !state.searchActive,
    }));
  }
}

export const globalService = new GlobalService(globalStore);
