import { Query } from '@datorama/akita';
import { GlobalStore, GlobalState, globalStore } from './global.store';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { shareReplay, filter, map } from 'rxjs/operators';

export class GlobalQuery extends Query<GlobalState> {
  searchActive$ = this.select(state => state.searchActive).pipe(shareReplay(1));

  constructor(protected store: GlobalStore) {
    super(store);
  }
}

export const globalQuery = new GlobalQuery(globalStore);
