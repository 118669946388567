import * as React from 'react';
import { ListItem, Avatar, Typography, ListItemAvatar, Paper, withStyles, Divider, WithStyles, Grid } from '@material-ui/core';
import { styles } from '@/components/ItemCandidate/styles';
import { Candidate } from '@/state/candidate.model';
import { calculateAgeOld, createStringAgeOld, createCandidateCvPrintUrl } from '@/helpers';
import ItemBase from '@/components/ItemBase';
import { Item } from '@/components/List';

// @TODO THK
// Melhorar a lógica para consumir o objeto do candidato,
// principalmente para pegar as iniciais.

type ItemCandidateProps = {
  candidate: Candidate;
  key: string | number;
  i?: number;
} & WithStyles<typeof styles>;

const ItemCandidateComponent: React.FC<ItemCandidateProps> = ({ classes, candidate, ...props }) => (
  <Grid item xs={12} component={Item} {...props}>
    <a
      className={classes!.link}
      href={createCandidateCvPrintUrl({
        contactId: candidate!.contact_id,
        jobId: candidate!.job_id,
        organizationId: candidate!.organization_id,
      })}
      target="_blank"
      rel="noopener"
    >
      <ItemBase
        avatarTitle={`${candidate!.contact_first_name[0]}${Boolean(candidate!.contact_last_name) && candidate!.contact_last_name[0] || ''}`}
        avatarSrc={candidate!.contact_main_picture}
        avatarAlt={candidate!.contact_first_name}
      >
        <Typography className={classes!.truncate} variant="h6" component="h1">
          {`${candidate!.contact_first_name} ${Boolean(candidate!.contact_last_name) && candidate!.contact_last_name || ''}`}
          <strong>
            {Boolean(candidate!.contact_birth_date)
              ? createStringAgeOld(calculateAgeOld(candidate!.contact_birth_date))
              : null}
          </strong>
        </Typography>
        <Divider />
        <Typography className={classes!.truncate} variant="subtitle1" component="h3">
          {candidate!.contact_trajectory.length > 0 && candidate!.contact_trajectory[0].company.name}
        </Typography>
        <Typography className={classes!.truncate} variant="body2" component="h2">
          {candidate!.contact_trajectory.length > 0 && candidate!.contact_trajectory[0].title}
        </Typography>
      </ItemBase>
    </a>
  </Grid>
);

export const ItemCandidate = withStyles(styles)(ItemCandidateComponent);
