import React from 'react';
import ReactDOM from 'react-dom';
import { CssBaseline } from '@material-ui/core';
import Routes from '@/routes';
import { akitaDevtools } from '@datorama/akita';
import { Root } from '@/components/Root';

akitaDevtools({
  logTrace: process.env.IS_DEV === 'true',
});

ReactDOM.render(
  <Root>
    <CssBaseline />
    <Routes/>
  </Root>
, document.getElementById('root'));
