import * as React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Button, withStyles } from '@material-ui/core';
import { styles } from '@/containers/site-detail/components/HeaderDetails/styles';
import { Job } from '@/state';

const textMetaStyle = {
  root: {
    display: 'flex',
    width: '100%',
    alignItems: 'baseline',
  },
  textMetaInfo: {
    display: 'inline-block',
  }
};

const BaseTextMetaInfo = ({ number, category, classes}) => (
  <div className={classes!.root}>
    <Typography style={{ marginRight: 8, fontWeight: 700 }} className={classes!.textMetaInfo} variant="h5" component="span">{number}</Typography>
    <Typography className={classes!.textMetaInfo} variant="body1" component="span">{category}</Typography>
  </div>
)

const TextMetaInfo = withStyles(textMetaStyle)(BaseTextMetaInfo);

const HeaderDetailsComponent: React.FC<{
  classes?: { [k in keyof typeof styles]: string };
  siteId: string;
  progress: Partial<Job['progress']>;
}> = ({ classes, siteId, progress = {} }) => (
  <header className={classes!.header}>
    <div>
      <TextMetaInfo number={progress.current || 0} category="mapeados"/>
      <TextMetaInfo number={(progress.max || 0) - (progress.current || 0)} category="não mapeados"/>
      {/* <TextMetaInfo number={8} category="no show"/> */}
    </div>
    <Button variant="contained" color="primary" component={Link} to={`/sites/${siteId}/candidates`}>
      Ver candidatos
    </Button>

  </header>
);

export const HeaderDetails = withStyles(styles)(HeaderDetailsComponent);
