import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { authQuery } from '@/state';

import PrivateRoute from '@/containers/private';
import Login from '@/containers/login';
import Dashboard from '@/containers/dashboard';
import Search from '@/containers/search';
import Sites from '@/containers/sites';
import SiteDetail from '@/containers/site-detail';
import SiteCandidates from '@/containers/site-candidates';
import SiteMap from '@/containers/map';
import { Subscription } from 'rxjs';

export default class extends React.Component {
  subs: Subscription[] = [];

  state = {
    isLoggedIn: false,
    redirTo: undefined,
  }

  componentWillMount() {
    this.subs.push(authQuery.isLoggedIn$.subscribe(isLoggedIn => {
      this.setState({ isLoggedIn });
    }));

    if (window.location.pathname !== '/' && window.location.pathname !== '/login') {
      this.setState({ redirTo: window.location.pathname });
    }
  }

  componentWillUnmount() {
    this.subs.forEach(s => s.unsubscribe());
  }

  render () {
    const { isLoggedIn, redirTo } = this.state
    if (!isLoggedIn) {
      return (
        <BrowserRouter>
          <Switch>
            <Route path="/login" exact component={Login} />
            <Redirect from="*" to={'/login' + (redirTo ? `?redirect=${redirTo}` : '')} />
          </Switch>
        </BrowserRouter>
      );
    }

    return (
      <BrowserRouter>
        <Switch>
          <PrivateRoute path="/dashboard" exact component={Dashboard} title="Projeto Vale" backButton={false} />
          <PrivateRoute path="/search" exact component={Search} title="Busca de Contatos"/>
          <PrivateRoute path="/map" exact component={SiteMap} title="Mapa"/>
          <PrivateRoute path="/sites" exact component={Sites} title="Jobs" searchButton />
          <PrivateRoute path="/sites/:siteId" exact component={SiteDetail} title="Job"/>
          <PrivateRoute path="/sites/:siteId/candidates" exact component={SiteCandidates} title="Contatos"/>
          <Redirect from="*" to={redirTo || '/sites'} />
        </Switch>
      </ BrowserRouter>
    );
  }
};
