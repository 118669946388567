import { JobStore, jobStore } from './job.store';
import { track } from '@/utils/actions';
import { requestElasticOld, requestElasticApplications } from '@/utils/api';
import { authQuery } from './auth.query';
import { jobQuery } from './job.query';
import { first } from 'rxjs/operators';
import { ID } from '@datorama/akita';
import { Job } from './job.model';

const pageSize = 10;
const fieldsWithWeight = [
  'job_confirmation_id^5',
  'job_full_title_text^3',
  'job_client_full_text^3',
  'job_full_status_text^3',
  'job_completion',
  'job_name',
  'job_public_id',
  'job_status1_stage',
  'job_status2_phase',
  'job_team_list',
  'company_name',
  'job_position_id',
];


const mountJobAddressGeo = (lat, lon) => ({
  distance: '10km',
  job_address_geo: {
    lat,
    lon,
  },
})

export class JobService {
  @track()
  async fetchJob(id: ID) {
    try {
      jobStore.update({ loading: true });
      const jobProgressPromise = this.fetchJobMetrics(id);
      const orgId = await authQuery.org$.pipe(first()).toPromise();
      const body = {
        query: {
          bool: {
            must: [{ term: { organization: { value: orgId } } }, { term: { id: { value: id } } }],
          },
        },
        sort: { job_created_at: { order: 'desc' } },
        from: 0,
        size: 1,
      };

      const {
        hits: { hits: jobs },
      } = await requestElasticOld('/bl_report_jobmatrix/_search', { method: 'POST', body: JSON.stringify(body) });
      const progress = await jobProgressPromise;
      jobStore.add(
        jobs.map(({ _source }) => ({
          ..._source,
          progress,
          status: _source.job_status1_stage === 'Ativa' ? 'Ativa' : 'Concluída',
          title: _source.job_name,
        }))
      );
    } catch (error) {
      this.fetchJobsError(error);
    }
  }

  @track()
  async fetchJobs(page = 0, jobAddressGeo?) {
    try {
      jobStore.update({ loading: true });
      const [orgId, searchBy] = await Promise.all([
        authQuery.org$.pipe(first()).toPromise(),
        jobQuery.searchBy$.pipe(first()).toPromise(),
      ]);
      const must = [].concat({ term: { organization: { value: orgId } } })

      if (Boolean(jobAddressGeo)) {
        const geoDistanceData = mountJobAddressGeo(jobAddressGeo.lat, jobAddressGeo.lon);
        must.push({ geo_distance: geoDistanceData });
      }

      const body = {
        query: {
          bool: {
            ...(searchBy ? {
              minimum_should_match: 1,
              should: [
                {
                  multi_match: {
                    query: searchBy,
                    type: 'phrase_prefix',
                    fields: fieldsWithWeight,
                  },
                },
                {
                  simple_query_string: {
                    query: searchBy,
                    default_operator: 'AND',
                    fields: fieldsWithWeight,
                  },
                },
              ],
            } : {}),
            // must_not: [{ nested: { path: 'excluded', query: { term: { 'excluded.is_excluded': true } } } }],
            must,
          },
        },
        sort: { job_created_at: { order: 'desc' } },
        from: page * pageSize,
        size: pageSize,
      };

      const {
        hits: { hits: jobs },
      } = await requestElasticOld('/bl_report_jobmatrix/_search', { method: 'POST', body: JSON.stringify(body) });


      const result: Job[] = await Promise.all( // @TODO this is a temporary workaround
        jobs.map(async ({ _source }) => ({
          ..._source,
          progress: await this.fetchJobMetrics(_source.id),
          status: _source.job_status1_stage === 'Ativa' ? 'Ativa' : 'Concluída',
          title: _source.job_name,
        }))
      )

      // @TODO - @matheus aqui eu fiz só para funcionar.
      if (Boolean(jobAddressGeo)) jobStore.set(result)
      else jobStore.add(result)

    } catch (error) {
      this.fetchJobsError(error);
    }
  }

  @track()
  fetchJobsError(error: any) {
    jobStore.update({ error });
  }

  @track()
  async fetchJobMetrics(job_id: ID) {
    try {
      jobStore.update({ loading: true });
      const body = {
        size: 0,
        aggs: {
          job: {
            filter: { term: { job_id } },
            aggs: {
              pipe: {
                terms: { field: 'application_pipe', size: 10 },
                aggs: { pipe: { terms: { field: 'application_status', size: 10 } } },
              },
            },
          },
        },
      };

      const {
        aggregations: {
          job: {
            pipe: { buckets: pipes },
          },
        },
      } = await requestElasticApplications('/application/_search', {
        method: 'POST',
        body: JSON.stringify(body),
      });

      let progress = pipes.reduce(
        (agg, { key, doc_count }) => {
          if (key !== 'SR' && key !== 'PU') {
            agg.current += doc_count;
          }
          agg.max += doc_count;
          return agg;
        },
        { min: 0, max: 0, current: 0 }
      );

      return progress;
    } catch (error) {
      this.fetchJobsError(error);
    }
  }

  @track()
  async search(searchBy?: string) {
    jobStore.update({ searchBy, entities: {}, ids: [] });
  }
}

export const jobService = new JobService();
