import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Job } from './job.model';

export interface JobState extends EntityState<Job> {
  searchBy?: string;
}

@StoreConfig({ name: 'job' })
export class JobStore extends EntityStore<JobState, Job> {}

export const jobStore = new JobStore();

