import { AuthStore, authStore, AuthState } from './auth.store';
import request from '@/utils/api';
import { setAuthCookie, getAuthCookie, removeAuthCookie } from '@/utils/auth';
import { track } from '@/utils/actions';

const client_id = '5187fd4f6cfed8e3d98b76cf';

export class AuthService {
  constructor(private authStore: AuthStore) {}

  @track()
  async login({ email, password }) {
    this.authStore.update({ processing: true });

    try {
      const { data: authData } = await request('/auth/password', {
        method: 'POST',
        body: JSON.stringify({
          username: email,
          password,
          client_id,
          grant_type: 'password',
        }),
      });

      setAuthCookie(authData.id, authData.expires_at);
      this.checkLogin();
    } catch (error) {
      this.loginFailed(error);
    }
  }

  @track()
  logout() {
    this.authStore.update({ processing: true });
    removeAuthCookie();
    this.checkLogin();
  }

  @track()
  loginSuccess({ user }: { user: AuthState['user'] }) {
    this.authStore.update({ processing: false, user });
  }

  @track()
  loginFailed(error) {
    console.error(error);
    this.authStore.update({ processing: false, user: undefined });
  }

  @track()
  async me() {
    const { data: meData } = await request('/user/me');
    return meData;
  }

  @track()
  async fetchOrg() {
    const { data: org } = await request('/org');
    authStore.update({ org });
  }

  @track()
  async checkLogin() {
    if (!getAuthCookie()) return this.authStore.update({ processing: false });
    try {
      const [ meData ] = await Promise.all([
        this.me(),
        this.fetchOrg(),
      ])
      this.loginSuccess(meData);
    } catch (error) {
      this.loginFailed(error);
    }
  }
}

export const authService = new AuthService(authStore);

authService.checkLogin();

export default authService;
