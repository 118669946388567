import { createStyles, Theme } from "@material-ui/core";

const drawerWidth = 320;
const appbarSize = 64;

export const styles = (theme: Theme) =>
  createStyles({
    appbar: {
      flex: '0 0 64px',
      display: 'flex',
      justifyContent: 'center',
      minHeight: appbarSize,
      backdropFilter: 'blur(2px)',
    },
    grow: {
      flexGrow: 1,
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'stretch',
      flexGrow: 1,
      width: '100vw',
      height: '100vh',
      overflow: 'hidden',
    },
    content: {
      flex: '1 0 auto',
      margin: `${theme.spacing.unit}px`,
      maxWidth: `calc(100vw - ${theme.spacing.unit * 3}px - ${drawerWidth}px)`,
      width: 720,
      backgroundColor: 'transparent',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    centralize: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 24,
    },
    main: {
      position: 'relative',
      display: 'flex',
      flex: '1 1 auto',
      height: '100%',
      alignItems: 'flex-start',
      justifyContent: 'center',
      padding: `${appbarSize + 30}px 16px 72px`,
      overflow: 'auto',
      width: '100%',
      margin: '0 auto',
      scrollBehavior: 'smooth',
      willChange: 'overflow',
      ['-webkitOverflowScrolling']: 'touch',
      ['-msOverflowStyle']: 'none',
    },
    bottomNavigation: {
      position: 'fixed',
      left: 0,
      bottom: 0,
      width: '100%',
      flex: '0 0 56px !important',
    },
    grid: {
      maxWidth: 720,
      minHeight: '100%',
    },
    toolbar: theme.mixins.toolbar,
  });
