import { fetch as fetchPolyfill } from 'whatwg-fetch';
import { getAuthCookie } from './auth';
import { authQuery } from '@/state';
import { first } from 'rxjs/operators';

export default async function request<Result = any>(uri: string, init?: RequestInit): Promise<Result> {
  if (!uri.startsWith('/')) return fetchPolyfill(uri, init);

  // here is BL requests only, but we can still request http uris using fetch
  init = init || {};
  init.headers = init.headers || {};
  if (getAuthCookie()) {
    init.headers['authorization'] = getAuthCookie();
  }
  if (!init.headers['content-type']) {
    init.headers['content-type'] = 'application/json';
  }
  if (typeof init.body === 'object' && !(init.body instanceof Buffer)) {
    init.body = JSON.stringify(init.body);
  }

  const response = await fetchPolyfill(`https://${process.env.API_URL}${uri}`, init);

  return response.json();
};

export async function requestElasticOld<Result = any>(uri: string, init?: RequestInit): Promise<Result> {
  init = init || {};
  init.headers = init.headers || {};
  if (getAuthCookie()) {
    init.headers['authorization'] = getAuthCookie();
  }
  if (!init.headers['content-type']) {
    init.headers['content-type'] = 'application/json';
  }
  if (typeof init.body === 'object' && !(init.body instanceof Buffer)) {
    init.body = JSON.stringify(init.body);
  }

  const response = await fetchPolyfill(`https://apisa.bigland.co/elasticsearch${uri}`, init);

  return response.json();
};

export async function requestElasticApplications<Result = any>(uri: string, init?: RequestInit): Promise<Result> {
  const orgAuth = await authQuery.orgAuth$.pipe(first()).toPromise();

  init = init || {};
  init.headers = init.headers || {};
  if (orgAuth) {
    init.headers['authorization'] = `Basic ${orgAuth}`;
  }
  if (!init.headers['content-type']) {
    init.headers['content-type'] = 'application/json';
  }
  if (typeof init.body === 'object' && !(init.body instanceof Buffer)) {
    init.body = JSON.stringify(init.body);
  }

  const response = await fetchPolyfill(`https://sa.bigland.co/es/db${uri}`, init);

  try {
    return response.json();
  } catch (error) {
    return response.text();
  }
};
