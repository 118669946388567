import * as React from 'react';
import { ListItem, Avatar, ListItemAvatar, Paper, withStyles, WithStyles } from "@material-ui/core";
import { styles } from '@/components/ItemBase/styles';

type ItemProps = {
  avatarSrc?: string;
  avatarTitle?: string;
  avatarAlt?: string;
  children?: React.ReactNode | React.ReactNodeArray;
} & WithStyles<typeof styles>;

const ItemComponent: React.FC<ItemProps> = (props): JSX.Element => {
  const [avatarSrc, setAvatarSrc] = React.useState(props.avatarSrc);

  const handleOnError = () => {
    setAvatarSrc(null);
  }

  return (
    <Paper className={props.classes!.root} elevation={1}>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar className={props.classes!.avatar} src={avatarSrc} alt={props.avatarAlt} onError={handleOnError}>
            {props.avatarTitle}
          </Avatar>
        </ListItemAvatar>
        <div className={props.classes!.content}>
          {props.children}
        </div>
      </ListItem>
    </Paper>
);
}
export const ItemBase = withStyles(styles)(ItemComponent)
