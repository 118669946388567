import * as React from 'react';
import { withStyles, Typography } from '@material-ui/core';
import ProgressHeader from '@/components/Progress/ProgressHeader';
import ProgressContent from '@/components/Progress/ProgressContent';
import { calculatePercentage } from '@/helpers';
import { styles } from '@/components/Progress/styles';

const InitialProps = {
  minValue: 0,
  maxValue: 0,
  currentValue: 0,
}

type ProgressProps = { classes?: { [k in keyof typeof styles]: string } } & typeof InitialProps;

const ProgressComponent: React.FC<ProgressProps> = (props): JSX.Element => {

  const { minValue, maxValue, currentValue, classes } = props;
  const percentValue = `${calculatePercentage(minValue || 0, maxValue || 0, currentValue || 0)}%`;

  return (
    <div className={classes!.root}>
      <ProgressHeader currentValue={currentValue} maxValue={maxValue} />
      <ProgressContent
        minValue={minValue}
        maxValue={maxValue}
        currentValue={currentValue}
        percentValue={percentValue}
      >
        <Typography className={classes!.label} variant="overline">{percentValue}</Typography>
      </ProgressContent>
    </div>
  );
}

export const Progress = withStyles(styles)(ProgressComponent);
