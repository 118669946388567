import { createStyles } from "@material-ui/core";

export const styles = createStyles({
  root: {
    cursor: 'pointer',
  },
  link: {
    textDecoration: 'none',
  },
  avatar: {},
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  content: {
    flex: '1 1 auto'
  },
  footer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  truncate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});
