import { QueryEntity } from '@datorama/akita';
import { CandidateStore, CandidateState, candidateStore } from './candidate.store';
import { Candidate } from './candidate.model';

export class CandidateQuery extends QueryEntity<CandidateState, Candidate> {

  constructor(protected store: CandidateStore) {
    super(store);
  }

}

export const candidateQuery = new CandidateQuery(candidateStore);
