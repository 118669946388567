import * as React from 'react';
import { withRouter } from 'react-router-dom';

import { AppBar, Toolbar, Typography, Grid, withStyles, IconButton, WithStyles, Menu, MenuItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { MoreVert, ArrowBack, Search, ExitToApp } from '@material-ui/icons';
import BottomNavigation from '@/components/BottomNavigation';
import { styles } from '@/components/Layout/styles';

import { globalService, authService } from '@/state';

type LayoutProps = {
  title?: string;
  backButton?: boolean;
  searchButton?: boolean;
  history?: any;
} & WithStyles<typeof styles>;

const LayoutContainer: React.SFC<LayoutProps> = (props): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  function handleMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleSignOut() {
    handleClose();
    authService.logout();
  }

  return (
    <div className={props.classes!.root}>
      <AppBar position="fixed" className={props.classes!.appbar} color="primary">
        <Toolbar>
          {props.backButton ? (
            <IconButton color="inherit" aria-label="Back" onClick={() => props.history.goBack()}>
              <ArrowBack />
            </IconButton>
          ) : null}
          <Typography variant="h6" color="inherit" className={props.classes!.grow} id="header-title">
            {props.title}
          </Typography>
          {props.searchButton ? (
            <IconButton color="inherit" aria-label="Back" onClick={() => globalService.toggleSearchField()}>
              <Search />
            </IconButton>
          ) : null}
          <div>
            <IconButton
              aria-owns={open ? 'menu-appbar' : undefined}
              aria-haspopup="true"
              onClick={handleMenu}
              aria-label="Menu"
              color="inherit"
            >
              <MoreVert />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleSignOut}>
                <ListItemIcon>
                  <ExitToApp />
                </ListItemIcon>
                <ListItemText>Sair</ListItemText>
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <div className={props.classes!.main}>
        <Grid className={props.classes!.grid} container direction="column" alignItems="flex-start" spacing={24}>
          {props.children}
        </Grid>
      </div>
      <BottomNavigation className={props.classes!.bottomNavigation} pathname={props.history.location.pathname} />
    </div>
  );
};

export const Layout = withRouter(withStyles(styles)(LayoutContainer));
