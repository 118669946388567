import * as React from 'react';
import { Helmet } from 'react-helmet';
import { withStyles, Paper } from '@material-ui/core';
import LoginBox from '@/components/LoginBox';
import { styles } from '@/containers/login/styles';
import { authService, authQuery } from '@/state';
import { Subscription } from 'rxjs';
import Loading from '@/components/Loading';

const AuthLoading = () => <Loading />;

class LoginLayout extends React.Component<{
  classes?: { [k in keyof typeof styles]: string };
}> {
  onSubmit = ({ email, password }) => {
    authService.login({ email, password });
  };

  state = {
    isProcessing: true,
  };
  sub: Subscription = null;

  componentWillMount() {
    this.sub = authQuery.isProcessing$.subscribe(isProcessing => {
      this.setState({ isProcessing });
    });
  }

  componentWillUnmount() {
    this.sub.unsubscribe();
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Helmet>
          <title>Login PDQ</title>
        </Helmet>
        {!this.state.isProcessing ? (
          <Paper className={classes!.paper} elevation={1}>
            <LoginBox onSubmit={this.onSubmit} />
          </Paper>
        ) : (
          <AuthLoading />
        )}
      </React.Fragment>
    );
  }
}

export const Login = withStyles(styles)(LoginLayout);
