import * as React from 'react';
import { Paper, Typography, withStyles } from '@material-ui/core';
import { styles } from '@/components/InfoBox/styles';

type InfoBoxProps = {
  classes?: { [k in keyof typeof styles]: string };
  title: string;
  children: React.ReactNode;
}

const InfoBoxComponent: React.FC<InfoBoxProps> = (props): JSX.Element => {

  const { title, classes, children, ...rest} = props;

  return (
    <Paper
      className={classes!.root}
      role="button"
      elevation={1}
      {...rest}
    >
      <Typography variant="body1">{title}</Typography>
      {children}
    </Paper>
  );
}

export const InfoBox =  withStyles(styles)(InfoBoxComponent);
