import { createStyles } from '@material-ui/core';

export const styles = createStyles({
  root: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
});
