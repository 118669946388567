import * as React from 'react';
import { Root } from '@/components/Root';
import { Layout } from '@/components/Layout';


export const AppWrapper = ({ children, title, backButton = true, searchButton = false }) => (
  <Layout title={title} backButton={backButton} searchButton={searchButton}>
    {children}
  </Layout>
);
