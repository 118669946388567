export const styles: any = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 1 60%',
    marginLeft: 'auto',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: 10,
  },
  progress: {
    position: 'relative',
    display: 'flex',
    flex: '1 1 auto',
    alignItems: 'center',
    justifyContent: 'center',
    height: '18px',
    backgroundColor: '#DAF5F2',
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
  },
  progressCompleted: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    backgroundColor: '#069697'
  },
  label: {
    position: 'relative',
    color: theme.palette.secondary.main,
    fontWeight: 900,
    lineHeight: 1,
  },
  textValue: {
    lineHeight: 1,
  }
});
