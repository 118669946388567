import * as React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { styles } from '@/containers/site-detail/components/SectionDetails/styles';

const SectionDetailsComponent: React.FC<{
  classes?: { [k in keyof typeof styles]: string };
  title: string;
  children: React.ReactNode | React.ReactNodeArray;
}> = ({ classes, title, children }): JSX.Element => (
  <section className={classes!.root}>
    <Typography variant="h6">{title}</Typography>
    {children}
  </section>
);

export const SectionDetails = withStyles(styles)(SectionDetailsComponent);
