import * as React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import ProgressContent from '@/containers/site-detail/components/ProgressDetails/ProgressContent';
import { calculatePercentage } from '@/helpers';
import { styles } from '@/containers/site-detail/components/ProgressDetails/styles';

const MIN_VALUE = 0

const ProgressDetailsComponent: React.FC<{
  classes?: { [k in keyof typeof styles]: string };
  total: number;
  value: number;
  label: string;
}> = ({ classes, total, value, label }) => {

  const percentValue = `${calculatePercentage(MIN_VALUE, total, value || 0)}%`;
  return (
    <div className={classes!.root}>
      <header className={classes!.header}>
        <Typography style={{ marginRight: 16, fontWeight: 700 }} variant="subtitle1">{value}</Typography>
        <Typography variant="subtitle2" color="textSecondary">{label}</Typography>
      </header>
      <div className={classes!.content}>
        <ProgressContent
          minValue={MIN_VALUE}
          maxValue={total}
          currentValue={value}
          percentValue={percentValue}
        />
        <Typography style={{
          width: 31,
          marginLeft: 8,
          lineHeight: 1,
        }} variant="overline">{percentValue}</Typography>
      </div>
    </div>
  );
}

export const ProgressDetails = withStyles(styles)(ProgressDetailsComponent);
