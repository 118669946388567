import { createStyles } from '@material-ui/core';

export const styles = createStyles({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },

  input: {
    marginLeft: 8,
    flex: 1,
  },

  iconButton: {
    padding: 10,
  },

  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
});
