import { ID } from '@datorama/akita';
import { candidateStore } from './candidate.store';
import { track } from '@/utils/actions';
import { authQuery, jobStore } from '@/state';
import { first } from 'rxjs/operators';
import { requestElasticApplications as requestElasticDB } from '@/utils/api';

const pageSize = 10;

export class CandidateService {
  @track()
  async fetchCandidates(job_id: ID, page = 0) {
    try {
      candidateStore.update({ loading: true });
      const orgId = await authQuery.org$.pipe(first()).toPromise();
      const body = {
        query: {
          bool: {
            must: [
              {
                bool: {
                  must: [
                    { term: { organization_id: { value: orgId } } },
                    { term: { job_id: { value: job_id } } },
                    // { term: { application_status: { value: 'AW' } } },
                    // { term: { application_pipe: { value: 'SR' } } },
                  ],
                },
              },
            ],
          },
        },
        size: pageSize,
        from: page * pageSize,
      };

      const {
        hits: { hits: candidates },
      } = await requestElasticDB('/application/_search', {
        method: 'POST',
        body: JSON.stringify(body),
      });
      this.fetchCandidatesSuccess(candidates);
    } catch (error) {
      this.fetchCandidatesError(error);
    }
  }

  @track()
  fetchCandidatesError(error: any) {
    candidateStore.update({ error });
  }

  @track()
  fetchCandidatesSuccess(candidates: any[]) {
    candidateStore.add(
      candidates.map(({_source}) => ({
        ..._source,
        id: _source.contact_id,
        // @TODO THK: map to entity here
      }))
    );
    candidateStore.update({ loading: false });
  }

  @track()
  async fetchCandidatesMetrics(jobId: string) {
    try {
      candidateStore.update({ loading: true, entities: {}, ids: [], error: null });
      const orgId = await authQuery.org$.pipe(first()).toPromise();
      const baseQuery = {
        size: 0,
        query: {
          bool: {
            must: [
              {
                term: {
                  organization_id: orgId,
                },
              },
              {
                nested: {
                  path: 'applications',
                  query: {
                    term: {
                      'applications.job_id': jobId,
                    },
                  },
                },
              },
            ],
          },
        },
      };
      const queries = [
        {
          ...baseQuery,
          aggs: {
            education___degree: {
              terms: {
                field: 'education___degree',
                size: 100,
                order: {
                  _count: 'desc',
                },
              },
            },
          },
        },
        {
          ...baseQuery,
          aggs: { is_pcd: { terms: { field: 'is_pcd', size: 2, order: { _count: 'desc' } } } },
        },
      ];

      const {
        responses: [degreesAgg, pcdAgg],
      } = await requestElasticDB('/contact/_msearch', {
        method: 'POST',
        body: queries.reduce((agg, query) => `${agg}{}\n${JSON.stringify(query)}\n`, ''),
      });
      this.fetchMetricsSuccess({ degreesAgg, pcdAgg, jobId });
    } catch (error) {
      console.log('this.fetchCandidatesMetrics', error);
    }
  }

  @track()
  fetchMetricsSuccess({ degreesAgg, pcdAgg, jobId }) {
    const candidatesMetrics = {
      PCD: 0,
    };
    if (pcdAgg && pcdAgg.aggregations && pcdAgg.aggregations.is_pcd && pcdAgg.aggregations.is_pcd.buckets) {
      pcdAgg.aggregations.is_pcd.buckets.forEach(({ doc_count, key_as_string }) => {
        if (key_as_string === 'true') candidatesMetrics.PCD = doc_count;
      });
    }
    if (
      degreesAgg &&
      degreesAgg.aggregations &&
      degreesAgg.aggregations.education___degree &&
      degreesAgg.aggregations.education___degree.buckets
    ) {
      degreesAgg.aggregations.education___degree.buckets.forEach(({ doc_count, key }) => {
        candidatesMetrics[`Formação: ${key}`] = doc_count;
      });
    }

    jobStore.update(state => {
      if (!state.entities[jobId]) return state;
      return {
        ...state,
        entities: {
          ...state.entities,
          [jobId]: {
            ...state.entities[jobId],
            candidatesMetrics,
          },
        },
      };
    });
  }

  @track()
  async search(value: string = '') {
    if (!value || !value.toString().trim()) {
      return candidateStore.update({ loading: false, entities: {}, ids: [], error: null });
    }

    try {
      candidateStore.update({ loading: true, entities: {}, ids: [], error: null });
      const orgId = await authQuery.org$.pipe(first()).toPromise();

      const body = {
        from: 0,
        size: 25,
        min_score: 5,
        query: {
          bool: {
            must: [
              {
                bool: {
                  must: [{ term: { organization_id: { value: orgId } } }],
                },
              },
            ],
            should: [
              // { nested: {
              //   path: "contact_source",
              //   query: {
              //     term: { "contact_source.source_url": { value: value } }
              //   }
              // } },
              // {
              //   simple_query_string: {
              //     query: value,
              //     fields: ['contact_first_name^8', 'contact_last_name^8'],
              //     default_operator: 'and',
              //   },
              // },
              // {
              //   multi_match: {
              //     query: value,
              //     fields: ['contact_first_name^2', 'contact_last_name^2', 'contact_phones^3', 'contact_emails^2'],
              //     type: 'best_fields',
              //     operator: 'and',
              //   },
              // },
              // {
              //   multi_match: {
              //     query: value,
              //     fields: ['contact_first_name^5', 'contact_last_name^5', 'contact_phones^3', 'contact_emails^2'],
              //     type: 'phrase_prefix',
              //     operator: 'and',
              //   },
              { term: { source_url: { value: value } } },
              {
                simple_query_string: {
                  query: value,
                  fields: ['first_name^8', 'last_name^8'],
                  default_operator: 'and',
                },
              },
              {
                multi_match: {
                  query: value,
                  fields: ['first_name^2', 'last_name^2', 'phones^3', 'emails^2'],
                  type: 'best_fields',
                  operator: 'and',
                },
              },
              {
                multi_match: {
                  query: value,
                  fields: ['first_name^5', 'last_name^5', 'phones^3', 'emails^2'],
                  type: 'phrase_prefix',
                  operator: 'and',
                },
              },
            ],
            minimum_should_match: 1,
          },
        },
      };

      const {
        hits: { hits: candidates },
      } = await requestElasticDB('/contact/_search', {
        method: 'POST',
        body: JSON.stringify(body),
      });
      this.fetchCandidatesSuccess(candidates.map(({ _source: contact }) => ({
        _source: {
          contact_birth_date: contact.birth_date,
          contact_first_name: contact.first_name,
          contact_last_name: contact.last_name,
          contact_created_at: contact.created_at,
          contact_main_picture: contact.main_picture,
          contact_emails: contact.emails,
          contact_phones: contact.phones,
          contact_trajectory: contact.trajectory,
          contact_education: contact.education,
          contact_id: contact.contact_id,
          organization_id: contact.organization_id,
        }
      })));
    } catch (error) {
      this.fetchCandidatesError(error);
    }
  }
}

export const candidateService = new CandidateService();
