import { createStyles } from '@material-ui/core';

export const styles = createStyles({
  link: {
    display: 'flex',
    flexDirection: 'row',
    textDecoration: 'none',
    cursor: 'pointer',
    height: 98,
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  footer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  truncate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }
});
