import * as React from 'react';
import { Typography, withStyles, WithStyles, Grid } from "@material-ui/core";
import Tag from '@/components/Tag';
import Progress from '@/components/Progress';
import { styles } from '@/components/ItemJob/styles';
import { Job } from '@/state/job.model';
import { Link } from 'react-router-dom';
import ItemBase from '@/components/ItemBase';
import { Item } from '@/components/List';
import { get } from 'lodash';
export type JobType = Job;
type ItemJobProps = {
  job: Partial<Job>;
  key: string | number;
  i?: number;
} & WithStyles<typeof styles>;

const ItemJobComponent: React.FC<ItemJobProps> = ({ classes, job = {}, ...props }: ItemJobProps) => (
  <Grid item xs={12} component={Item} {...props}>
    <Link className={classes!.link} to={`/sites/${get(job, 'id')}`}>
      <ItemBase
        avatarTitle={get(job, ['company_name'], ' ').charAt(0)}
        avatarSrc={get(job, 'company_picture_key')}
        avatarAlt={get(job, 'company_name')}
        >
        <Typography variant="body1">{get(job, 'company_name')}</Typography>
        <Typography className={classes!.truncate} variant="h6">{get(job, 'title')}</Typography>
        <footer className={classes!.footer}>
          <Tag type={get(job, 'status')} />
          <Progress minValue={get(job, 'progress.min', 0)} maxValue={get(job, 'progress.max', 1)} currentValue={get(job, 'progress.current', 0)} />
        </footer>
      </ItemBase>
    </Link>
  </Grid>
);

export const ItemJob = withStyles(styles)(ItemJobComponent)
