import React, { ChangeEvent, FormEvent } from 'react';
import { Button, Typography, FormControl, InputLabel, FormHelperText, withStyles, Avatar, FilledInput } from '@material-ui/core';
import { styles } from '@/components/LoginBox/styles';
import { sizeIsLessthan } from '@/helpers';

type State = {
  email: string;
  password: string;
  errors: { email?: string; password?: string };
}

type Props = {
  classes?: { [k in keyof typeof styles]: string };
  onSubmit: ({ email, password }) => any;
}

const minSize = 3;

class LoginBoxComponent extends React.Component<Props, State> {
  initialState: State = {
    email: '',
    password: '',
    errors: {},
  }

  state = this.initialState;

  reset = () => this.setState({ ...this.initialState });

  validateAndSetEmail = (e: ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value || '';
    if (sizeIsLessthan(minSize, email) || !(email || '').includes('@')) {
      return this.setState({
        email,
        errors: {
          ...this.state.errors,
          email: 'Email invalido'
        }
      });
    }
    const errors: State['errors'] = { ...this.state.errors };
    delete errors.email;
    return this.setState({ email, errors });
  }

  validateAndSetPassword = (e: ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value || '';
    if (sizeIsLessthan(minSize, password)) {
      return this.setState({
        password,
        errors: {
          ...this.state.errors,
          password: 'Senha não pode ser menor que 6 caracteres.'
        }
      });
    }

    const errors: State['errors'] = { ...this.state.errors };
    delete errors.password;
    return this.setState({ password, errors });
  }

  isValid = () => Object.keys(this.state.errors).length === 0;

  onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (this.isValid()) {
      const { email, password } = this.state;
      this.props.onSubmit({ email, password });
    }
    return false;
  }

  render() {
    const { classes } = this.props;
    const { errors } = this.state;

    return (
      <React.Fragment>
        <Avatar
          alt="Vale"
          src="http://logok.org/wp-content/uploads/2014/08/Vale-logo-880x660.png"
          className={classes.avatar}
        />
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <form onSubmit={this.onSubmit}>
          <FormControl
            margin="normal"
            variant="filled"
            className={classes.formControl}
            error={!!errors.email}
            fullWidth
            required
          >
            <InputLabel htmlFor="email">E-mail</InputLabel>
            <FilledInput
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              aria-describedby="email"
              onChange={this.validateAndSetEmail}
              autoFocus
            />

            <FormHelperText id="email">{errors.email}</FormHelperText>
          </FormControl>

          <FormControl
            margin="normal"
            variant="filled"
            className={classes.formControl}
            error={!!errors.password}
            fullWidth
            required
          >
            <InputLabel htmlFor="password">Senha</InputLabel>
            <FilledInput
              id="password"
              name="password"
              type="password"
              autoComplete="off"
              aria-describedby="password"
              onChange={this.validateAndSetPassword}
              autoFocus
            />

            <FormHelperText id="email">{errors.password}</FormHelperText>
          </FormControl>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!this.isValid()}
          >
            Entrar
          </Button>

        </form>
      </React.Fragment>
    );
  }
}

export const LoginBox = withStyles(styles)(LoginBoxComponent);
