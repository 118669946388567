import React, { Component } from 'react';
import { RouteProps } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Subscription } from 'rxjs';

import { Paper, Typography, CircularProgress } from '@material-ui/core';
import List from '@/components/List';
import ItemCandidate from '@/components/ItemCandidate';
import { candidateQuery, candidateService, Candidate } from '@/state';
import Loading from '@/components/Loading';

type State = {
  candidates: Candidate[];
  loading: boolean;
}

export class SiteCandidates extends Component<RouteProps, State> {

  state = {
    candidates: [],
    loading: true,
  }

  subs: Subscription[] = [];

  async componentWillMount() {
    this.subs.push(candidateQuery.selectAll().subscribe(candidates => {
      this.setState({ candidates });
    }));

    await candidateService.fetchCandidates(this.props.match.params.siteId);
    this.setState(prevState => ({ ...prevState, loading: false }));
  }

  componentWillUnmount() {
    this.subs.forEach(s => s.unsubscribe());
  }

  renderCandidates = () => (this.state.candidates!.length > 0)
    ? (
      <List>
        {this.state.candidates.map((candidate, index) => (
          <ItemCandidate i={index} key={index} candidate={candidate} />
        ))}
      </List>)
    : null;
    // : this.renderEmptyList();

  renderEmptyList = () => (
    <div>
      <Typography>Nenhum Contato Encontrado</Typography>
    </div>
  )

  renderLoadingOrList = () => (this.state.loading)
    ? <Loading />
    : this.renderCandidates();

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Contatos</title>
        </Helmet>

        {this.renderLoadingOrList()}

      </React.Fragment>
    );
  }
}
