import * as React from 'react';
import { PoseGroup } from 'react-pose';
import { withStyles, WithStyles } from "@material-ui/core";
import { styles } from '@/components/ItemBase/styles';

type ListProps = {
  children: any;
} & WithStyles<typeof styles>;

const ListComponent: React.FC<ListProps> = (props): JSX.Element => (
  <PoseGroup animateOnMount>
    {props.children}
  </PoseGroup>
);

export const List = withStyles(styles)(ListComponent)
