import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Grid, Paper } from '@material-ui/core';
import HeaderDetails from '@/containers/site-detail/components/HeaderDetails';
import SectionDetails from '@/containers/site-detail/components/SectionDetails';
import ProgressDetails from '@/containers/site-detail/components/ProgressDetails';
import { Subscription } from 'rxjs';
import { jobQuery, jobService, candidateService } from '@/state';
import { JobType } from '@/components/ItemJob/ItemJob';

export class SiteDetail extends React.Component<any, { job?: JobType }> {

  subs: Subscription[] = [];

  state = {
    job: undefined,
  }

  componentWillMount() {
    this.subs.push(jobQuery.selectEntity(this.props.match.params.siteId).subscribe(this.updateJob));
  }

  componentWillUnmount() {
    this.subs.forEach(s => s.unsubscribe());
  }

  updateJob = (job: JobType) => {
    if (!job) {
      return jobService.fetchJob(this.props.match.params.siteId);
    }

    if (this.state.job && this.state.job.candidatesMetrics) return;

    candidateService.fetchCandidatesMetrics(job.id);
    this.setState({ job }, () => {
      if (!this.state.job) return;
      document.getElementById('header-title').innerHTML = this.state!.job!.title;
    });
  }

  renderCandidateMetrics = () => {
    if (!this.state.job || !this.state.job.candidatesMetrics) return null;
    return Object.keys(this.state.job.candidatesMetrics).map(key =>
      <ProgressDetails key={key} total={this.state.job.progress && this.state.job.progress.max || 0} value={this.state.job.candidatesMetrics[key] || 0} label={key} />
    );
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{(this.state.job && this.state.job.title) || 'Planta'}</title>
        </Helmet>

        <Grid item xs={12} style={{
          marginTop: 148,
          width: '100%',
        }}>
          <img
            style={{
              width: '100%',
              objectFit: 'cover',
              position: 'absolute',
              top: 0,
              right: 0,
              left: 0,
              height: 200,
              zIndex: 3,
            }}
            src="https://images.immedia.com.br/2/2131_2_EL.jpg"
          />
          <HeaderDetails siteId={this.props.match.params.siteId} progress={this.state.job && this.state.job.progress} />

          <Paper style={{ padding: 16 }} elevation={1}>
            {/* <SectionDetails title="Volumetria de mapeamentos">
              <div style={{ width: '100%', height: 100 }}>
                <ResponsiveContainer>
                  <AreaChart
                    data={mockDataArea}
                    margin={{
                      top: 5, right: 0, left: 0, bottom: 5,
                    }}
                  >
                    <Area type="monotone" dataKey="uv" stroke="#26C1C9" fill="#26C1C9" />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </SectionDetails> */}
            <SectionDetails title="Perfil dos contatos">
              {/* <div style={{ width: '100%', height: 120 }}>
                <ResponsiveContainer>
                  <PieChart>
                    <Pie data={mockDataPie} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={50} fill="#8884d8" label />
                  </PieChart>
                </ResponsiveContainer>
              </div> */}

              {this.renderCandidateMetrics()}
              {/* <ProgressDetails total={1323} value={3} label="PCD" />
              <ProgressDetails total={1323} value={213} label="Formacao: fundamental" />
              <ProgressDetails total={1323} value={134} label="Formacao: médio" />
              <ProgressDetails total={1323} value={10} label="Formacao: superior" />
              <ProgressDetails total={1323} value={14} label="Formacao: pós" />
              <ProgressDetails total={1323} value={1} label="Inglês intermedeiário" /> */}
            </SectionDetails>
          </Paper>
        </Grid>
      </React.Fragment>
    );
  }
}
