import { get as getCookie, set as setCookie, remove as removeCookie } from 'js-cookie';

const authCookieName = 'bl-auth-token-id';

export function getAuthCookie() {
  return getCookie(authCookieName)
}

export function setAuthCookie(value: string, expires: number) {
  setCookie(authCookieName, value, {
    expires,
  });
}

export function removeAuthCookie() {
  removeCookie(authCookieName);
}
