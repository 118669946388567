import * as React from 'react';
import { withStyles, Typography } from '@material-ui/core';
import { styles } from '@/components/Tag/styles';
import { JobType } from '../ItemJob/ItemJob';

const statusStyle = {
  Ativa: {
    backgroundColor: '#03A9F4',
  },

  'Concluída': {
    backgroundColor: '#2FA84F',
  },
}

type TagProps = {
  classes?: { [k in keyof typeof styles]: string };
  type: JobType['status'];
};

const TagComponent: React.FC<TagProps> = (props): JSX.Element => (
  <span className={props.classes!.root} style={statusStyle[props.type] || statusStyle['Concluída']}>
    <Typography className={props.classes!.text} variant="caption">{props.type}</Typography>
  </span>
);

export const Tag = withStyles(styles)(TagComponent);
