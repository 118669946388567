import * as React from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core';

export const theme = createMuiTheme({
  palette: {
    primary: {
      contrastText: '#FFF',
      main: '#069697',
    },
    secondary: {
      contrastText: '#222222',
      main: '#EBAC12'
    }
  },
  overrides: {
    MuiAppBar: {
      positionFixed: {
        backgroundColor: 'rgba(6, 150, 151, 0.95) !important',
        boxShadow: 'none',
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        backgroundColor: 'transparent',
        borderRight: '0',
      },
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily:
      '-apple-system,system-ui,BlinkMacSystemFont,' +
      '"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
  }
});

const rootStyles = withStyles({
  wrapper: {
    display: 'flex',
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    background: `linear-gradient(204.79deg, ${theme.palette.secondary.main} 51.35%, rgba(255, 255, 255, 0) 103.97%), ${theme.palette.primary.main}`,
  },
});

const RootComponent = ({ children, classes }) => (
  <MuiThemeProvider theme={theme}>
    <div className={classes.wrapper}>{children}</div>
  </MuiThemeProvider>
);

export const Root = rootStyles(RootComponent);
