import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import InfoBox from '@/components/InfoBox';
import { Grid, Typography, Button } from '@material-ui/core';

class DashboardContainer extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Dashboard</title>
        </Helmet>

        <Grid item xs={12}>
          <Typography variant="h4">Dashboard</Typography>
          <Typography variant="h6">(Em breve)</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={24}>
            <Grid item xs={6}>
              <InfoBox title="Faltam">
                <Typography variant="h3" style={{ color: '#EA3D2F' }}>
                  0
                </Typography>
              </InfoBox>
            </Grid>
            <Grid item xs={6}>
              <InfoBox title="Mapeados">
                <Typography variant="h3" style={{ color: '#F3AA18' }}>
                  0
                </Typography>
              </InfoBox>
            </Grid>
            <Grid item xs={6}>
              <InfoBox title="Mobilidade">
                <Typography variant="h3" style={{ color: '#2FA84F' }}>
                  0
                </Typography>
              </InfoBox>
            </Grid>
            <Grid item xs={6}>
              <InfoBox title="Alerta">
                <Typography variant="h3" style={{ color: '#F3AA18' }}>
                  0
                </Typography>
              </InfoBox>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export const Dashboard = DashboardContainer;
