import { Query } from '@datorama/akita';
import { AuthState, authStore } from './auth.store';
import { getAuthCookie } from '@/utils/auth';
import shash from 'sha256';

export class AuthQuery extends Query<AuthState> {
  isLoggedIn$ = this.select(state => getAuthCookie() && !!state.user);
  isProcessing$ = this.select(state => state.processing);
  user$ = this.select(state => state.user);
  state$ = this.select(state => state);
  org$ = this.select(state => state.user && state.user.default_organization ? state.user.default_organization : undefined);
  orgAuth$ = this.select(state => state.org ? btoa(`${state.org.id}:${shash(`${state.org.id}:${state.org.private_key}`)}`) : undefined);
}

export const authQuery = new AuthQuery(authStore);
