import * as React from 'react';
import GoogleMapReact from "google-map-react";
import { Marker } from '@/containers/map/components/Marker';
import { mapStyles } from '@/containers/map/components/map-styles';
import { existJobs } from '@/helpers';

// @Todo - Extrair para um arquivo de configuracão
const MAP_CONFIG = {
  bootstrap: {
    key: "AIzaSyDksNNOzOY48-acTuXc5P02M1tby9n2Ovo",  // @TODO - colocar no arquivo `env.config.js`
    language: "pt-BR",
    region: "BR"
  },
  zoom: 12,
  styles: mapStyles
}

export const SimpleMap: React.FC<any> = (props): JSX.Element => {

  const {jobs, center, zoom, history, onBoundsChange } = props;

  const renderMarkers = React.useCallback(() =>
    existJobs(jobs) && jobs!.map((job, index) => (
      <Marker
        onClick={() => history.push(`/sites/${job!.id}`)}
        key={index}
        lat={job!.job_address_geo && job!.job_address_geo!.lat}
        lng={job!.job_address_geo && job!.job_address_geo!.lon}
        text={job!.company_name}
      />
    )), [jobs]);

  return (
    <GoogleMapReact
      bootstrapURLKeys={MAP_CONFIG.bootstrap}
      defaultCenter={center}
      defaultZoom={zoom || MAP_CONFIG.zoom}
      options={{ styles: MAP_CONFIG.styles }}
      onChange={onBoundsChange}
      yesIWantToUseGoogleMapApiInternals
    >
      {renderMarkers()}
    </GoogleMapReact>
  )
}
