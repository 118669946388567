import React, { Component, ChangeEvent } from 'react';
import { RouteProps } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Subscription, Subject } from 'rxjs';

import { Paper, Typography, CircularProgress, Grid } from '@material-ui/core';
import List from '@/components/List';
import ItemCandidate from '@/components/ItemCandidate';
import { candidateQuery, candidateService, Candidate } from '@/state';
import Loading from '@/components/Loading';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import SearchInput from '@/components/SearchInput';

type State = {
  candidates: Candidate[];
  loading: boolean;
  search: string;
};

export class Search extends Component<RouteProps, State> {
  state = {
    candidates: [],
    loading: false,
    search: null
  }

  subs: Subscription[] = [];

  async componentWillMount() {
    candidateService.search('')
  }

  async componentDidMount() {
    this.subs.push(
      candidateQuery.selectAll().subscribe(candidates => this.setState({ candidates })),
      this.search$sink.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(search => this.setState({ search }))
      )
      .subscribe(value => candidateService.search(value))
    );
  }

  componentWillUnmount() {
    this.subs.forEach(s => s.unsubscribe());
  }

  renderCandidates = () => (this.state.candidates!.length > 0)
    ? (
      <List>
        {this.state.candidates.map((candidate, index) => (
          <ItemCandidate i={index} key={index} candidate={candidate} />
        ))}
      </List>)
    : null;

  renderEmptyList = () => (
    <Paper style={{ width: '100%' }}>
      {this.state.search ? (
        <Typography>Nenhum Contato Encontrado</Typography>
      ) : null}
    </Paper>
  )

  renderLoadingOrList = () => (this.state.loading)
    ? <Loading />
    : this.renderCandidates();


  search$sink = new Subject<string>();
  onChange = (e: ChangeEvent<HTMLInputElement>) => this.search$sink.next(e.target.value || '');
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Contatos</title>
        </Helmet>

        <Grid item xs={12} style={{ width: '100%', height: 'auto' }}>
          <SearchInput
            type="text"
            placeholder="Busque por nome, telefone, email"
            onChange={this.onChange}
          />
        </Grid>

        {this.renderLoadingOrList()}

      </React.Fragment>
    );
  }
}
