import * as React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { styles } from '@/components/Progress/styles';

const InitialProps = {
  maxValue: 0,
  currentValue: 0,
}

type ProgressHeaderProps = { classes?: { [k in keyof typeof styles]: string } } & typeof InitialProps;

const ProgressHeaderComponent: React.FC<ProgressHeaderProps> = (props): JSX.Element => (
  <header className={props.classes!.header}>
    <Typography className={props.classes!.textValue} variant="overline" color="primary">{props.currentValue}</Typography>
    <Typography className={props.classes!.textValue} variant="overline" color="primary">{props.maxValue}</Typography>
  </header>
);

export default withStyles(styles)(ProgressHeaderComponent);
