import * as React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import { LocationOn } from '@material-ui/icons';

export const Marker = ({ text, onClick }: any) => (
  <Tooltip disableFocusListener title={text}>
    <IconButton onClick={onClick}>
      <LocationOn fontSize="large" color="primary" />
    </IconButton>
  </Tooltip>
);
