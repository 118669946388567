import { QueryEntity } from '@datorama/akita';
import { JobStore, JobState, jobStore } from './job.store';
import { Job } from './job.model';
import { debounceTime, shareReplay } from 'rxjs/operators';

export class JobQuery extends QueryEntity<JobState, Job> {
  searchBy$ = this.select(state => state.searchBy).pipe(debounceTime(300), shareReplay(1));

  constructor(protected store: JobStore) {
    super(store);
  }

}

export const jobQuery = new JobQuery(jobStore);
