import { Job } from "@/state";

export const API_URL: string = 'https://api.bigland.co';

export type CandidateCvPrint = {
  contactId: string;
  jobId: string;
  organizationId: string;
  urlPath?: string;
  type?: number | string;
  blind?: boolean | string;
  user?: boolean | string;
}

export const concatWords = (...args: string[]) => Boolean(args) && Array.isArray(args) && args.join(' ');

export const calculatePercentage = (min: number, max: number, value: number): number => Math.round((value * 100) / ((max - min) || 1));

export const isEmpty = (v: string) => !v;

export const sizeIsLessthan = (size: number, v: string) => isEmpty(v) || v.length <= size;

export const setActiveBottomNavigationAction = (path: string): string => {

  if (path.includes('/sites')) return '/sites'
  if (path.includes('/search')) return '/search'
  if (path === '/map') return '/map'

  return '/dashboard';
}
export const calculateAgeOld = (date: Date | string): number => {

    const today = new Date();
    const birth = new Date(date);

    return today.getFullYear() - birth.getFullYear();
  }

export const createStringAgeOld = (age: number): string => `, ${age}`;

export const createCandidateCvPrintUrl =
  (params: CandidateCvPrint): string =>
    `${API_URL}/${params.urlPath || 'contact/print'}?` +
    `contact_id=${params.contactId}` +
    (params.jobId ? `&job_id=${params.jobId}` : '') +
    `&organization_id=${params.organizationId}` +
    `&type=${params.type || 3}` +
    `&blind=${params.blind || false}` +
    `&user=${params.user || false}`;

export const existJobs = (jobs: Job[]): boolean => Boolean(jobs) && Array.isArray(jobs) && jobs.length > 0;
