import * as React from 'react';
import { withStyles, WithStyles, CircularProgress } from "@material-ui/core";
import { styles } from '@/components/Loading/styles';

const LoadingComponent: React.FC<{
} & WithStyles<typeof styles>> = ({ classes }): JSX.Element => (
  <div className={classes!.root}>
    <CircularProgress color="inherit" />
  </div>
);

export const Loading = withStyles(styles)(LoadingComponent)
