import { logAction } from '@datorama/akita';
import { Subject, Observable, timer, empty } from 'rxjs';
import { distinctUntilChanged, debounce } from 'rxjs/operators';
import { isEqual } from 'lodash';
declare var gtag: any;

const debounceDistinctUntilChanged = (delay: number) => source$ => {
  return new Observable(observer => {
    let lastSeen = {};

    return source$
      .pipe(
        debounce(value => {
          if (isEqual(value, lastSeen)) {
            return timer(delay);
          } else {
            lastSeen = value;
            return empty();
          }
        })
      )
      .subscribe(observer);
  });
};

const event$sink = new Subject();

event$sink
  .pipe(debounceDistinctUntilChanged(300))
  .subscribe(({ propertyKey, category }) =>
    gtag('event', propertyKey, { event_category: category, event_label: propertyKey })
  );

/**
 * GA Track actions and register as akita action
 * @param event
 * @param category
 */
export function track(category: string = 'engagement') {
  return function(target, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;
    descriptor.value = function(...args) {
      logAction(propertyKey);
      event$sink.next({ propertyKey, category });
      return originalMethod.apply(this, args);
    };

    return descriptor;
  };
}
