import * as React from "react";
import { Link } from 'react-router-dom';
import { BottomNavigation as MuiBottomNavigation, BottomNavigationAction } from "@material-ui/core";
import { Dashboard, People, LocationOn, Work } from '@material-ui/icons';
import { setActiveBottomNavigationAction } from "@/helpers";
import { BottomNavigationActionProps } from '@material-ui/core/BottomNavigationAction';

const BottomNavigationActionEnhanced = (props: { to: string } & BottomNavigationActionProps) => <BottomNavigationAction {...props} component={Link}/>;

function BottomNavigation(props) {
  const [value, setValue] = React.useState(props.pathname);

  React.useEffect(() => {
    setValue(setActiveBottomNavigationAction(props.pathname))
  }, [props.pathname]);

  return (
    <MuiBottomNavigation
      style={{ flex: '0 0 auto'}}
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      {...props}
    >
      <BottomNavigationActionEnhanced to="/sites" label="Jobs" value="/sites" icon={<Work />} />
      <BottomNavigationActionEnhanced to="/dashboard" label="Dashboard" value="/dashboard" icon={<Dashboard />} />
      <BottomNavigationActionEnhanced to="/map" label="Mapa" value="/map" icon={<LocationOn />} />
      <BottomNavigationActionEnhanced to="/search" label="Contatos" value="/search" icon={<People />} />
    </MuiBottomNavigation>
  );
}

export { BottomNavigation };
