import * as React from 'react';
import { Helmet } from 'react-helmet';
import { withStyles } from '@material-ui/core';

import { jobService, jobQuery } from '@/state';
import { Subscription } from 'rxjs';
import { SimpleMap } from '@/containers/map/components/SimpleMap';
import { styles } from '@/containers/map/styles';
import { existJobs } from '@/helpers';

class MapContainer extends React.Component<any, any> {
  state = {
    jobs: [],
    page: 0,
    center: { lat: -23.52756, lng: -46.678573 },
  }

  subs: Subscription[] = [];

  componentDidMount() {
    this.subs.push(jobQuery.selectAll().subscribe(jobs => {
      this.setState({ jobs });
    }));
    jobService.fetchJobs(this.state.page, { lat: this.state.center.lat, lon: this.state.center.lng });
  }

  componentWillUnmount() {
    this.subs.forEach(s => s.unsubscribe());
  }

  loadJobs = (map) => {
    jobService.fetchJobs(this.state.page, { lat: map.center.lat, lon: map.center.lng });
  }

  renderMap = () => (
    <SimpleMap
      history={this.props.history}
      jobs={this.state.jobs}
      onBoundsChange={this.loadJobs}
      center={this.state.center}
    />
  );

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>SiteMap PDQ</title>
        </Helmet>

        <div className={this.props.classes!.root}>
          {this.renderMap()}
        </div>
      </React.Fragment>
    );
  }
}

export const Map = withStyles(styles)(MapContainer);
